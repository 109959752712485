<template>
  <div class="home">
    <Loading v-if="isUniversalLoading" />
    <div class="container h-100 p-0">
      <div class="row overflow-auto message-section ">
        <div class="col-12 bg-danger p-0">
          <div class="container p-0">
            <div class="row pt-1">
              <div class="col-10 p-0">
                <div class="d-flex align-items-end px-1">
                  <img class="rounded-circle user-avatar" src="https://picsum.photos/200" alt="" srcset="">
                  <div class="flex-grow-0 ml-1 bg-light bot-message">
                    <p class="p-1 m-0 ">Lorem, ipsum dolor lorem </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row pt-1">
              <div class="offset-2 col-10">
                <div class="d-flex align-items-end justify-content-end">
                  <div class="flex-grow-0 mr-1 bg-light user-message">
                    <p class="p-1 m-0">Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi quos, cumque hic
                      dignissimos expedita ipsa autem corrupti quas magni in quia eligendi tenetur porro, illo reiciendis
                      veritatis aliquid laudantium perferendis. </p>
                  </div>
                  <img class="rounded-circle user-avatar" src="https://picsum.photos/200" alt="" srcset="">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row input-section">
        <div class="col-12 bg-dark bg-secondary">
          Ask me a question
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Loading.vue";

export default {
  components: {
    Loading,
  },
  computed: {
    isUniversalLoading() {
      return false;
    }
    // isUniversalLoading() {
    //   return this.$store.state.modals.isUniversalLoading;
    // },
  },

};
</script>

<style lang="scss">
@import "@/assets/scss/style.scss";

.message-section {
  height: 90%;
}

.input-section {
  height: 10%;
  min-height: 60px;
}

.user-avatar {
  width: 2.2rem;
  height: 2.2rem;
}

.bot-message {
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
  border-bottom-left-radius: 0.3rem;
  border-top-left-radius: 2rem;
}

.user-message {
  border-top-right-radius: 2rem;
  border-top-left-radius: 2rem;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 0.3rem;
}

.home {
  height: 100vh;
  background: #ffffff;
}
</style>
